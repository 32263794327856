import { FC } from 'react'
import { FlexboxGrid, Panel } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import AnchorLink from '../../AnchorLink'
import useUrls from '../../../services/useUrls'
import { PRIMARY_BRAND_COLOR } from '../../../utils/constants'
import NextArrowIcon from '../../Icons/NextArrowIcon'

import styles from '../../../styles/LandingPage/ViewAllCard.module.less'

const ViewAllCard: FC = () => {
  const { urlT } = useUrls()
  const { t } = useTranslation('landing')

  return (
    <div className={styles['see-all-card']}>
      <AnchorLink href={urlT('/products')} shallow>
        <Panel bodyFill>
          <FlexboxGrid align="middle" className={styles['all-brands-cta']}>
            <h4 className="margin-zero margin-right-spacer">
              {t('View all')}
            </h4>
            <NextArrowIcon color={PRIMARY_BRAND_COLOR} height="12" />
          </FlexboxGrid>
          <p className={styles.description}>
            <b>{t('More than 22 352 products from over 150 leading brands')}</b>
          </p>
        </Panel>
      </AnchorLink>
    </div>
  )
}

export default ViewAllCard
